<template>
  <!-- 添加图片 -->
  <el-dialog
    :title="addImageDialogVisible.title"
    :visible.sync="addImageDialogVisible.status"
    destroy-on-close
    width="21.6vw"
  >
    <el-form class="form" label-position="left" label-width="5.4vw">
      <el-form-item label="图片名称：" prop="name">
        <el-input v-model.trim="name" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item
        label="选择分类："
        label-position="left"
        label-width="6.3vw"
        style="margin-left: -0.9vw"
      >
        <el-select v-model="categoryId" placeholder="默认为系统分类">
          <el-option
            v-for="item in categoryOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="上传图标："
        label-position="left"
        label-width="5.4vw"
      >
        <UploadPic
          :editPicData="addImageDialogVisible"
          @url="handleUrl"
          @uploadPic="uploadPic"
          ref="uploadPic"
        />
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button class="form-btn cancel" @click="addImageDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="confirm">确定</Button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import UploadPic from 'c/basic/UploadPic';
export default {
  name: 'AddImage',
  components: {
    Button,
    UploadPic
  },
  data() {
    return {
      name: '',
      url: '',
      categoryId: '',
      categoryOptions: []
    };
  },
  computed: {
    addImageDialogVisible: {
      get() {
        return this.$store.state.materialLibrary.addImageDialogVisible;
      },
      set(val) {
        this.$store.commit('materialLibrary/setAddImageDialogVisible', val);
      }
    }
  },
  methods: {
    // 获取图片地址
    handleUrl(url) {
      this.url = url;
    },
    // 获取分类
    getImageList() {
      const params = {
        type: 3,
        order: 1,
        selectSystem: 0
      };
      this.$service.materialLibrary
        .styleResourceCategoryAll(params)
        .then(res => {
          if (res.data.status === 200) {
            this.categoryOptions = res.data.data;
            if (this.categoryId) return;
            this.categoryId = res.data.data[0].id;
          }
        });
    },

    confirm() {
      const name = this.name;
      const categoryId = this.categoryId;
      // 判断图片名称
      if (!name) {
        return this.$message({
          message: '请输入图片名称',
          type: 'warning'
        });
      }
      if (!categoryId) {
        return this.$message({
          message: '请选择图片分类',
          type: 'warning'
        });
      }
      this.$refs.uploadPic.finish();
    },
    uploadPic() {
      let title =
        this.addImageDialogVisible.title == '上传图片'
          ? 'styleImageAdd'
          : 'styleImageEidt';
      const params = {
        categoryId: this.categoryId,
        name: this.name,
        url: this.url,
        id: this.addImageDialogVisible.data?.id
      };
      this.$service.materialLibrary[title](params).then(res => {
        if (res.data.status === 200) {
          this.$message.success(res.data.msg);
          this.url = '';
          this.$emit('refresh');
          this.$store.commit('materialLibrary/setAddImageDialogVisible', '');
        }
      });
    }
  },
  watch: {
    addImageDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.data) {
          this.categoryId = JSON.parse(JSON.stringify(data.data.categoryId));
          this.name = JSON.parse(JSON.stringify(data.data.name));
          data.data.name;
          this.url = JSON.parse(JSON.stringify(data.data.url));
          data.data.url;
          this.getImageList();
        } else if (data.status) {
          this.name = '';
          this.categoryId = JSON.parse(JSON.stringify(data.categoryId));
          this.getImageList();
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
// 表单lable
.label {
  display: flex;
  font-size: 16px;
  /deep/ .el-form-item__label {
    font-size: 16px !important;
    padding-right: 0;
  }
  &-text {
    color: #333;
  }
  // 提示
  &-tip {
    flex: 1;
    color: #f06012;
  }
}

// 颜色选择
.fillColor-select {
  margin-bottom: 20px;
  &-wrap {
    padding: 15px 20px;
    border-radius: 4px;
    border: 1px dashed #507cff;
  }
  // 头部
  &-top {
    display: flex;
    margin-bottom: 10px;
    &-block {
      flex: 1;
      background: #f2f2f2;
      border: 1px solid #d2d2d2;
      border-left: 0;
      line-height: 30px;
      text-align: center;
      &.selected {
        background: #bfbfbf;
      }
      // 第一个元素
      &:first-child {
        border-left: 1px solid #d2d2d2;
      }
    }
  }
  // 色块选择
  &-block {
    box-sizing: border-box;
    flex: 1;
    height: 34px;
    border: 1px solid #d2d2d2;
    cursor: pointer;
    border-left: 0;
    // 第一个元素
    &:first-child {
      border-left: 1px solid #d2d2d2;
    }
    // 选中
    &.selected {
      border-top-width: 2px;
      border-bottom-width: 2px;
      border-top-color: #2ed590;
      border-bottom-color: #2ed590;
      // 选中的第一个
      &:first-child {
        border-left-width: 2px;
        border-left-color: #2ed590;
      }
      // 选中的最后一个
      &:last-child {
        border-right-width: 2px;
        border-right-color: #2ed590;
      }
    }
    // 容器
    &-wrap {
      display: flex;
      // 设置不能选中，避免影响选择色块操作
      user-select: none;
      background: url(~a/workbench/materiaLibrary/block-bg.png);
    }
  }
  // 底图颜色选择
  &-bottom {
    display: flex;
    justify-content: space-between;
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      &-label {
        line-height: initial;
        white-space: nowrap;
        color: #507cff;
        font-size: 14px;
        margin-right: 6px;
      }
      /deep/ .el-input__inner {
        width: 75px;
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
      }
      .color-picker {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        /deep/ .el-color-picker {
          &__trigger {
            width: 100%;
            height: 100%;
            padding: 0;
          }
        }
      }
      .color-ipt /deep/ .el-input__inner {
        width: 125px;
        padding-right: 30px;
      }
      .ipt /deep/ .el-input__inner {
        width: 75px;
        text-align: center;
      }
    }
  }
}

// 样式预览
.style-preview {
  display: flex;
  margin-top: 15px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  &-item {
    flex: 1;
    height: 10px;
  }
}

.form-btn-group {
  margin-top: 0 !important;
}
</style>
