<template>
  <div class="ImageLibrary">
    <AssetsBin
      @categoryLine="getListData"
      @select="handleSelect"
      @emptyList="handleEmptyList"
    />
    <AssetsList
      @select="handleSelect"
      @search="handleSearch"
      @handleAllChecked="handleAllChecked"
      :checkedArr="checkedArr"
      :paging="paging"
      :select="select"
      ref="msg"
    />
    <div class="ImageLibrary-box" v-loading="loading">
      <div
        class="ImageLibrary-item"
        v-for="(item, index) in listData"
        :key="index"
        @mouseenter="mouseenter(item)"
        @mouseleave="mouseleave(item)"
      >
        <div class="ImageLibrary-img">
          <!-- 图标按钮 -->
          <div class="ImageLibrary-img-icon">
            <!-- 收藏按钮 -->
            <list-tooltip content="取消收藏">
              <i
                class="icon icon-list-collect1"
                v-show="
                  (!select && item.collection === 1) || search.typeNum == '7'
                "
                @click="handleCollect(item)"
              ></i>
            </list-tooltip>
            <el-dropdown
              trigger="click"
              ref="dropdown"
              @visible-change="val => dropdownChange(val, '7', index)"
              :hide-on-click="false"
            >
              <list-tooltip content="收藏">
                <i
                  class="icon icon-list-collect"
                  v-show="!select && item.mouse && item.collection === 0"
                  @click="handleCollect(item)"
                ></i>
              </list-tooltip>
              <el-dropdown-menu slot="dropdown">
                <FileDialog
                  @dropdownClose="dropdownClose(index)"
                  @handleCheck="id => handleCheck(id, item, index)"
                  ref="FileDialog"
                />
              </el-dropdown-menu>
            </el-dropdown>

            <!-- 编辑按钮 -->
            <list-tooltip content="编辑">
              <i
                class="icon icon-list-edit"
                @click="handleEdit(item)"
                v-show="
                  !select &&
                  item.mouse &&
                  search.system != 1 &&
                  search.typeNum == '3'
                "
              ></i>
            </list-tooltip>
            <!-- 选择状态按钮 -->
            <i
              :class="
                item.checked
                  ? 'icon icon-list-checked1'
                  : 'icon icon-list-checked'
              "
              v-if="select"
              @click="handleChecked(item)"
            ></i>
          </div>

          <img :src="listData[index].url" class="img" />
        </div>
        <el-tooltip
          :disabled="item.name.length < 4"
          :content="item.name"
          placement="right"
          effect="light"
        >
          <p class="ImageLibrary-img-name">{{ item.name }}</p>
        </el-tooltip>
      </div>
      <div v-if="!listData[0] && !loading" class="ImageLibrary-emptyBox">
        <div v-if="emptyStatus">
          <img src="~a/workbench/materiaLibrary/emptyData.png" />
          <p class="emptyText">暂无素材</p>
        </div>

        <div v-else>
          <img src="~a/workbench/materiaLibrary/emptySearch.png" />
          <p class="emptyText">抱歉，没有发现相关的内容</p>
          <p class="emptyTextSearch">换个关键词试试吧</p>
        </div>
      </div>
    </div>

    <AddImage @refresh="handleRefresh" />
  </div>
</template>

<script>
import FileDialog from 'c/basic/FileDialog.vue';
import AddImage from './AddImage.vue';
import AssetsBin from './AssetsBin.vue';
import AssetsList from './AssetsList.vue';
import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  components: { AssetsBin, AssetsList, AddImage, FileDialog, ListTooltip },
  name: 'ImageLibrary',
  data() {
    return {
      // 选择
      select: false,
      // 选中状态
      checked: false,
      // 选择中的数组
      checkedArr: [],
      // 鼠标经过显示
      mouse: false,
      // 收藏提示
      listData: [],
      // 获取数据参数
      search: {
        categoryId: '', // 分类id，默认查所有
        system: '0', // 当前tab 1为系统 0为其他
        keywords: '', // 搜索关键字
        typeNum: ''
      },
      // 分页数据
      paging: {
        page: 1,
        total: 0
      },
      // 空状态显示，true为一般空数据，false为搜索空数据
      emptyStatus: true,
      // 加载动画
      loading: true
    };
  },
  computed: {
    styleCurrentPaged: {
      get() {
        return this.$store.state.materialLibrary.styleCurrentPaged;
      }
    }
  },
  methods: {
    dropdownChange(val, text, index) {
      if (val) {
        this.$refs.FileDialog[index].getFileData(text);
      } else {
        this.$refs.FileDialog[index].refreshClassify(1);
      }
    },
    // 搜索
    handleSearch(val) {
      this.search.keywords = val;
      this.getListData();
    },
    // 刷新列表数据
    handleRefresh() {
      this.getListData();
    },
    // 清空列表数据
    handleEmptyList() {
      this.listData = [];
    },
    // 获取分页数据
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getListData();
    },
    // 获取数据
    getListData(data) {
      if (data) {
        this.search = data;
      }
      this.loading = true;
      const params = {
        categoryId: this.search.categoryId,
        system: this.search.system,
        keywords: this.search.keywords,
        page: this.styleCurrentPaged,
        selectCollection: this.search.system
      };
      for (var key in params) {
        params[key] === '' && delete params[key];
      }
      // 判断是否为搜索状态，用于空状态的显示效果
      this.emptyStatus = params.keywords ? false : true;
      let typeNum =
        this.search.typeNum == '7'
          ? 'styleImageCollectionList'
          : 'styleImageList';
      this.$service.materialLibrary[typeNum](params)
        .then(res => {
          if (res.data.status === 200) {
            if (res.data.totalPage < res.data.page && res.data.totalPage != 0) {
              this.$store.commit('materialLibrary/setStyleCurrentPaged', 1);
              this.getListData();
            }
            this.listData = res.data.data;
            this.paging = {
              page: res.data.page,
              total: res.data.total
            };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 打开编辑窗口
    handleEdit(val) {
      const data = {
        title: '编辑图片',
        status: true,
        data: val
      };
      this.$store.commit('materialLibrary/setAddImageDialogVisible', data);
    },
    // 收藏
    handleCheck(id, item, index) {
      const params = {
        id: item.id,
        categoryId: id
      };
      this.$service.materialLibrary.styleImageCollection(params).then(res => {
        if (res.data.status === 200) {
          this.$refs.dropdown[index].visible = false;
          this.getListData();
          this.$refs.msg.handleMessageBox('收藏成功');
        }
      });
    },
    // 取消收藏
    handleCollect(data) {
      if (data.collection) {
        let params = new FormData();
        params.append('imageId', data.id);
        this.$service.materialLibrary
          .styleImageCollectionDelBatch(params)
          .then(res => {
            if (res.data.status === 200) {
              this.getListData();
              this.$refs.msg.handleMessageBox('取消收藏');
            }
          });
      }
    },
    // 选择
    handleSelect(val) {
      this.select = val;
      this.checkedArr = val === false ? '' : this.checkedArr;
    },
    // 点击取消去除方法
    removeItem(arr, item) {
      let targetIndex = arr.findIndex(itemTemp => itemTemp === item);
      if (targetIndex !== -1) {
        arr.splice(targetIndex, 1);
      }
    },
    // 选中与取消
    handleChecked(item) {
      if (!item.checked) {
        item.checked = true;
        this.checkedArr.push(item.id);
      } else {
        item.checked = false;
        this.$refs.msg.handleSelectText('全选');
        this.removeItem(this.checkedArr, item.id);
      }
    },
    // 全选
    handleAllChecked(val) {
      if (val === false) {
        for (let i = 0; i < this.listData.length; i++) {
          this.listData[i].checked = val;
        }
        this.checkedArr = [];
      } else {
        this.checkedArr = [];
        for (let i = 0; i < this.listData.length; i++) {
          if (!this.listData[i].checked) {
            for (let i = 0; i < this.listData.length; i++) {
              this.listData[i].checked = true;
              this.checkedArr.push(this.listData[i].id);
            }
            break;
          } else {
            this.listData[i].checked = false;
          }
        }
      }
      if (this.listData.length === this.checkedArr.length) {
        this.$refs.msg.handleSelectText('取消全选');
      } else {
        this.$refs.msg.handleSelectText('全选');
      }
    },
    mouseenter(item) {
      this.$set(item, 'mouse', true);
    },
    mouseleave(item) {
      this.$set(item, 'mouse', false);
    },
    dropdownClose(index) {
      this.$refs.dropdown[index].visible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.ImageLibrary {
  &-box {
    height: calc(100vh - 641px);
    overflow: hidden;
    overflow-y: auto;
  }
  &-item {
    display: inline-block;
    margin: 0 9px 0 20px;
  }
  &-img {
    width: 50px;
    height: 50px;
    background: #e5e5e5;
    border-radius: 6px;
    overflow: hidden;
    .img {
      width: 50px;
      margin-top: -16px;
    }
    // 图标按钮
    &-icon {
      display: block;
      overflow: hidden;
      height: 16px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .icon-list-edit {
        margin-left: 16px;
      }
    }
    &-name {
      width: 50px;
      text-align: center;
      font-size: 14px;
      color: #666666;
      text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
      overflow: hidden;
      white-space: nowrap; /*不换行 控制单行文本*/
    }
  }
  // 空状态
  &-emptyBox {
    height: calc(100vh - 641px);
    overflow: hidden;
    overflow-y: auto;
    img {
      display: flex;
      margin: 40px auto 0;
      width: 212px;
      height: 130px;
    }
    .emptyText {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      font-size: 16px;
      text-align: center;
      margin: 40px auto 10px;
    }
    .emptyTextSearch {
      text-align: center;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1b1b1b;
      line-height: 16px;
      opacity: 0.5;
    }
  }
}
.el-dropdown-menu {
  background: none;
  text-align: justify;
  border: none;
  box-shadow: none;
  border-radius: none;
}
</style>
