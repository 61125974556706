var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ImageLibrary"},[_c('AssetsBin',{on:{"categoryLine":_vm.getListData,"select":_vm.handleSelect,"emptyList":_vm.handleEmptyList}}),_c('AssetsList',{ref:"msg",attrs:{"checkedArr":_vm.checkedArr,"paging":_vm.paging,"select":_vm.select},on:{"select":_vm.handleSelect,"search":_vm.handleSearch,"handleAllChecked":_vm.handleAllChecked}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"ImageLibrary-box"},[_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"ImageLibrary-item",on:{"mouseenter":function($event){return _vm.mouseenter(item)},"mouseleave":function($event){return _vm.mouseleave(item)}}},[_c('div',{staticClass:"ImageLibrary-img"},[_c('div',{staticClass:"ImageLibrary-img-icon"},[_c('list-tooltip',{attrs:{"content":"取消收藏"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                (!_vm.select && item.collection === 1) || _vm.search.typeNum == '7'
              ),expression:"\n                (!select && item.collection === 1) || search.typeNum == '7'\n              "}],staticClass:"icon icon-list-collect1",on:{"click":function($event){return _vm.handleCollect(item)}}})]),_c('el-dropdown',{ref:"dropdown",refInFor:true,attrs:{"trigger":"click","hide-on-click":false},on:{"visible-change":function (val) { return _vm.dropdownChange(val, '7', index); }}},[_c('list-tooltip',{attrs:{"content":"收藏"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.select && item.mouse && item.collection === 0),expression:"!select && item.mouse && item.collection === 0"}],staticClass:"icon icon-list-collect",on:{"click":function($event){return _vm.handleCollect(item)}}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('FileDialog',{ref:"FileDialog",refInFor:true,on:{"dropdownClose":function($event){return _vm.dropdownClose(index)},"handleCheck":function (id) { return _vm.handleCheck(id, item, index); }}})],1)],1),_c('list-tooltip',{attrs:{"content":"编辑"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.select &&
                item.mouse &&
                _vm.search.system != 1 &&
                _vm.search.typeNum == '3'
              ),expression:"\n                !select &&\n                item.mouse &&\n                search.system != 1 &&\n                search.typeNum == '3'\n              "}],staticClass:"icon icon-list-edit",on:{"click":function($event){return _vm.handleEdit(item)}}})]),(_vm.select)?_c('i',{class:item.checked
                ? 'icon icon-list-checked1'
                : 'icon icon-list-checked',on:{"click":function($event){return _vm.handleChecked(item)}}}):_vm._e()],1),_c('img',{staticClass:"img",attrs:{"src":_vm.listData[index].url}})]),_c('el-tooltip',{attrs:{"disabled":item.name.length < 4,"content":item.name,"placement":"right","effect":"light"}},[_c('p',{staticClass:"ImageLibrary-img-name"},[_vm._v(_vm._s(item.name))])])],1)}),(!_vm.listData[0] && !_vm.loading)?_c('div',{staticClass:"ImageLibrary-emptyBox"},[(_vm.emptyStatus)?_c('div',[_c('img',{attrs:{"src":require("a/workbench/materiaLibrary/emptyData.png")}}),_c('p',{staticClass:"emptyText"},[_vm._v("暂无素材")])]):_c('div',[_c('img',{attrs:{"src":require("a/workbench/materiaLibrary/emptySearch.png")}}),_c('p',{staticClass:"emptyText"},[_vm._v("抱歉，没有发现相关的内容")]),_c('p',{staticClass:"emptyTextSearch"},[_vm._v("换个关键词试试吧")])])]):_vm._e()],2),_c('AddImage',{on:{"refresh":_vm.handleRefresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }